// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';
// Ensure the UI forms module is initialized.
import '../components/ui-forms';

/**
 * Module calculation simple.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 */
(($, Brixx, brixxUtils) => {

    // Whether the calc module has already been initialized.
    if (typeof Brixx.modules.moduleCalcSimple !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for calc lists and forms.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.moduleCalcSimple = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            // (Re-)calculate form values on keyup event.
            $('.module-item-calc-form', context).once('calc-simple-calculate').on('keyup calculate', event => {
                const $form = $(event.currentTarget);

                // Extract form values.
                const values = Brixx.forms.getNumberValuesByNames([
                    'werkvertrag',
                    'gewinn',
                    'material',
                    'lohndurchschnitt',
                    'arbeitszeit',
                    'pax1',
                    'pax2',
                    'pax3',
                    'pax4',
                    'pax5'
                ], $form);

                // Calculate composite values.
                values.calcGewinn = brixxUtils.numberRound(values.werkvertrag / 100 * values.gewinn * -1);
                values.calcMaterial = brixxUtils.numberRound(values.material * -1);
                values.calcBudget = brixxUtils.numberRound(values.werkvertrag + values.calcGewinn + values.calcMaterial);
                values.calcArbeitsstunden = brixxUtils.numberRound((values.lohndurchschnitt !== 0) ? (values.calcBudget / values.lohndurchschnitt) : 0);
                for (let i = 1; i <= 5; i++) {
                    values['calcPax' + i + 'Hours'] = brixxUtils.numberRound((values['pax' + i] !== 0) ? (values.calcArbeitsstunden / values['pax' + i]) : 0);
                    values['calcPax' + i + 'Days'] = brixxUtils.numberRound((values['pax' + i] !== 0 && values.arbeitszeit !== 0) ? (values.calcArbeitsstunden / values.arbeitszeit / values['pax' + i]) : 0);
                    // Populate immediately to avoid a second loop.
                    Brixx.forms.setValue($('.elemPaxHours' + i, $form), values['calcPax' + i + 'Hours']);
                    Brixx.forms.setValue($('.elemPaxDays' + i, $form), values['calcPax' + i + 'Days']);
                }

                // Populate user-exposed values.
                Brixx.forms.setValue($('.elemWerkvertrag', $form), values.werkvertrag);
                Brixx.forms.setValue($('.elemGewinn', $form), values.calcGewinn);
                Brixx.forms.setValue($('.elemMaterial', $form), values.calcMaterial);
                Brixx.forms.setValue($('.elemBudget', $form), values.calcBudget);
                Brixx.forms.setValue($('.elemLohndurchschnitt', $form), values.lohndurchschnitt);
                Brixx.forms.setValue($('.elemArbeitsstunden', $form), values.calcArbeitsstunden);
                Brixx.forms.setValue($('.elemArbeitszeit', $form), values.arbeitszeit);
            }).trigger('calculate');
        }

    };

})(jQuery, Brixx, brixxUtils);
