// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// UI listings.
import '../components/ui-listings';
// Foundation framework.
import '../components/foundation';

/**
 * Contains all scripts specific to BrixxUser related views.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    /**
     * BRIXX module for BrixxUser related views.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.users = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('.brixx-user-form', context).once('user-form').each((index, form) => {
                const $form = $(form);

                const $adminCheckbox = $('.mod-checkbox', $form);
                if ($adminCheckbox.length) {
                    $adminCheckbox.on('click', () => {
                        if ($adminCheckbox.is(':checked')) {
                            $('.user-permissions', $form).hide();
                        }
                        else {
                            $('.user-permissions', $form).show();
                        }
                    });
                    if ($adminCheckbox.is(':checked')) {
                        $('.user-permissions', $form).hide();
                    }
                }
            });
        }

    };

})(jQuery, Brixx);
