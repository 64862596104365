// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';
// Ensure the UI forms module is initialized.
import '../components/ui-forms';
// Awesomplete.
import 'awesomplete';
// Awesomplete util.
import AwesompleteUtil from 'awesomplete-util';
// UI Awesomplete mods.
import '../components/ui-awesompletex';

/**
 * Contains expense item autocomplete JS.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 * @param {AwesompleteUtil} AwesompleteUtil
 *   Awesomplete utilities.
 */
(($, Brixx, brixxUtils, AwesompleteUtil) => {

    // Whether the calc module has already been initialized.
    if (typeof Brixx.modules.moduleExpenseItem !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for calc lists and forms.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.moduleExpenseItem = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: (context) => {

            // Initialize AwesompleteX bindings for expense item autocomplete.
            // Note, we don't have to implemenrt a detach function, as our
            // UI Awesomplete mods component will handle the detachment.
            $('input.awesompletex.awe-expense-preset, textarea.awesompletex.awe-expense-preset', context).once('awesompletex').each((index, element) => {
                const $expenseNameField = $(element);
                const elementName = $expenseNameField.attr('name');
                const fieldNameGroup = brixxUtils.getFieldNameGroup(elementName);
                const expenseUnitFieldName = fieldNameGroup ? (fieldNameGroup + '[unit]') : 'unit';
                const expensePriceFieldName = fieldNameGroup ? (fieldNameGroup + '[price]') : 'price';

                // Get the dependent form elements.
                const $form = $expenseNameField.closest('form');
                const $expenseUnitField = $('[name="' + expenseUnitFieldName + '"]', $form);
                const $expensePriceField = $('[name="' + expensePriceFieldName + '"]', $form);

                $expenseNameField.list = $expenseNameField.data('xlist') || [];

                if (typeof $expenseNameField.list === 'string') {
                    let settingsList = Brixx.getSetting($expenseNameField.list, null);
                    if (settingsList) {
                        $expenseNameField.list = settingsList.map(listItem => brixxUtils.isObject(listItem) ? {...listItem} : listItem);
                    }
                }

                if ($expenseNameField.list) {
                    const $label = $('label[for="' + $expenseNameField.attr('id') + '"]') || $expenseNameField.next('label');

                    AwesompleteUtil.attachCopyFun(AwesompleteUtil.createCopyFun('#' + $expenseNameField.attr('id'), 'price', '#' + $expensePriceField.attr('id'), true));
                    AwesompleteUtil.attachCopyFun(AwesompleteUtil.createCopyFun('#' + $expenseNameField.attr('id'), 'unit', '#' + $expenseUnitField.attr('id'), true));

                    $expenseNameField.awcx = AwesompleteUtil.start('#' + $expenseNameField.attr('id'), {
                        prepop: true
                    }, {
                        list: $expenseNameField.list,
                        minChars: $expenseNameField.data('minchars') || 2,
                        maxItems: $expenseNameField.data('maxitems') || 20,
                        filter: AwesompleteUtil.filterContains
                    });

                    // Move the label back after the input field.
                    if ($label.length > 0) {
                        $label.detach().insertAfter($expenseNameField);
                    }

                    // Whether to add lock icon.
                    if ($expenseNameField.hasClass('add-locks') && $expenseNameField.closest('.form-element').find('.lock').length === 0) {
                        const $lock = $('<div class="awesomplete-lock lock"><i class="fas fa-lock"></i><i class="fas fa-unlock"></i></div>');
                        if ($expenseNameField.hasClass('awe-found')) {
                            $lock.addClass('locked');
                        }
                        $lock.insertBefore($expenseNameField);
                        Brixx.attachModules($lock.parent());
                    }

                    // Bind selectcomplete handler for form recalculation.
                    $expenseNameField.on('awesomplete-selectcomplete', event => {
                        // Form recalculation is bound to the `keyup` event, so we
                        // just trigger that event on select-complete.
                        $expenseNameField.closest('form').trigger('calculate');

                        // Execute custom selectcomplete handlers, if there are any
                        // defined.
                        if ($expenseNameField.data('selectcomplete')) {
                            brixxUtils.executeFunctionByName($expenseNameField.data('selectcomplete'), event.currentTarget, event);
                        }
                    }).on('awesomplete-close', event => {
                        let reason;

                        if (typeof event.reason !== 'undefined') {
                            reason = event.reason;
                        }
                        else if (typeof event.originalEvent !== 'undefined' && typeof event.originalEvent.reason !== 'undefined') {
                            reason = event.originalEvent.reason;
                        }
                        else {
                            reason = 'undefined';
                        }

                        if (reason !== 'select') {
                            $(event.target).closest('.ui-listing-item').find('.locked').removeClass('locked');
                        }
                    });
                }
            });

        }

    };

})(jQuery, Brixx, brixxUtils, AwesompleteUtil);
