// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Ensure the UI forms module is initialized.
import '../components/ui-forms';

/**
 * Contains project module order specific JS handlers.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the calc module has already been initialized.
    if (typeof Brixx.modules.order !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for calc lists and forms.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.order = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('form.order-form', context).once('payablesFormInit').each((idxForm, form) => {
                const $form = $(form);

                $('.order-delivery-widget .form-element span').addClass('button');
                $('.order-delivery-widget .form-element')
                    .on('click', function () {
                        $('.order-delivery-widget .form-element span').removeClass('active');
                        $(this).find('span').addClass('active');
                    });
                $('.order-delivery-widget .form-element input:checked').click();


                $form.on('calculate keyup', event => {
                    let total = 0;

                    $('.ui-listing-item', $form).each((idxItem, item) => {
                        let $item = $(item);
                        let $amountElement = $item.find('.ma-amount');
                        let $priceElement = $item.find('.ma-price');
                        let $totalElement = $item.find('.ma-total');

                        let amount = Brixx.forms.getNumberValue($amountElement, 0);
                        let price = Brixx.forms.getNumberValue($priceElement, 0);
                        let subTotal = amount * price;
                        total += subTotal;

                        if (!$amountElement.is(':focus')) {
                            Brixx.forms.setValue($amountElement, amount);
                        }
                        if (!$priceElement.is(':focus')) {
                            Brixx.forms.setValue($priceElement, price);
                        }
                        Brixx.forms.setValue($totalElement, subTotal);
                    });

                    // @todo Show total sum in form.
                    Brixx.forms.setValue($('.order-total', $form), total);
                });
                $form.trigger('calculate');
            });
        }

    };

})(jQuery, Brixx);
